<template>
  <div class="mt-6 flex flex-col gap-4">
    <div
      class="grid grid-flow-col justify-start items-center cursor-pointer ml-2 w-fit"
      @click="goBackToCompanySettings"
    >
      <TrailIcon icon="local:arrow-left" />

      <p class="mx-2 text-trail-title4 text-trail-text-body-normal">
        {{ $t('costCentreBackToCompanySettings') }}
      </p>
    </div>

    <div class="flex flex-col gap-4">
      <div class="flex items-center justify-between mb-4">
        <h1 class="text-trail-title1 text-trail-text-title-normal">
          {{ $t('companySettingsCostCentreHeadTitle') }}
        </h1>

        <TrailButton @click="createCostCentreModalOpen = true"
          >{{ $t('createCentre') }}
        </TrailButton>
      </div>

      <div
        class="p-8 rounded-2xl bg-trail-background-secondary border-none flex flex-col gap-6"
      >
        <div class="flex flex-col gap-2">
          <h2 class="text-trail-title2 text-trail-text-title-normal">
            {{ $t('companySettingsCostCentreTitle') }}
          </h2>

          <p class="text-trail-body3 text-trail-text-caption-normal">
            {{ $t('companySettingsCostCentreSubTitle') }}
          </p>
        </div>

        <TrailDivider />

        <CostCentreTable
          :rows="costCentreList"
          :pagination="paginationData"
          :loading="costCentreLoading"
          @fetchData="fetchData"
          @pagination-changed="handlePageChanged"
        />
      </div>
    </div>

    <CreateCostCentreModal
      v-model:open="createCostCentreModalOpen"
      @reload-table="fetchData"
    />
  </div>
</template>

<script setup lang="ts">
import { useApiData } from '@/composables/useApiData.ts'
import { transformCostCentreResponse } from '@/api/transforms/costCentreTransforms.ts'
import CostCentreTable from '@/components/CompanySettings/CostCentre/CostCentreTable.vue'
import { computed } from 'vue'

definePage({
  name: 'cost-centre',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['pp.company.update-billing-details'],
  },
})
const currentPage = ref(1)
const router = useRouter()
const createCostCentreModalOpen = ref(false)

const {
  data: costCentreDataResponse,
  fetchData,
  loading: costCentreLoading,
} = useApiData('/cost-centre/v1/centres', {
  transformer: transformCostCentreResponse,
  params: () => ({
    page: currentPage.value,
    limit: 25,
  }),
  immediate: true,
})

const costCentreList = computed(() => costCentreDataResponse.value?.data ?? [])
const paginationData = computed(() => costCentreDataResponse.value?.meta)

const goBackToCompanySettings = () =>
  router.push('/platform/company-settings/billing-details')

const handlePageChanged = (page: number) => {
  currentPage.value = page
  fetchData()
}
</script>
