import { useToastsStore } from '@/stores/toasts'
import type { TrailToastProps } from '@/components/Trail/TrailToast.vue'

type TrailToastPayload = Omit<TrailToastProps, 'type' | 'open'>

export function useTrailToast() {
  const MAX_TOASTS_SHOWN = 1
  const toastsStore = useToastsStore()

  const toast = async (options: Omit<TrailToastProps, 'open'>) => {
    const id = toastsStore.nextToastId++

    if (toastsStore.toasts.length > MAX_TOASTS_SHOWN - 1) {
      await toastsStore.removeToast(toastsStore.toasts[0].id)
    }

    toastsStore.toasts.push({
      id,
      open: true,
      ...options,
    })
  }

  const normal = (options: TrailToastPayload) => {
    toast({ type: 'normal', ...options })
  }

  const positive = (options: TrailToastPayload) => {
    toast({ type: 'positive', ...options })
  }

  const negative = (options: TrailToastPayload) => {
    toast({ type: 'negative', ...options })
  }

  const warning = (options: TrailToastPayload) => {
    toast({ type: 'warning', ...options })
  }

  return {
    normal,
    positive,
    negative,
    warning,
  }
}
