import { useAuthStore } from '@/stores/auth'
import { removeAppLoading } from '@/utils/auth'
import { default as baseAxios } from 'axios'

const axios = baseAxios.create({
  withCredentials: true,
})

axios.defaults.baseURL =
  import.meta.env.MODE === 'development' ? '/api' : import.meta.env.VITE_API_URL
axios.defaults.headers['Accept'] = 'application/json'
axios.defaults.headers['Content-Type'] = 'application/json;charset=UTF-8'

let refreshStatus = false
let refreshStatusPromiseResolve
let stuckedRequestsCount = 0

axios.interceptors.response.use(
  (response) => response,
  async function (error) {
    stuckedRequestsCount++
    const store = useAuthStore()
    const originalRequest = error.config
    const responseURL = error.request.responseURL

    if (!responseURL.includes('/refresh')) {
      await refreshStatus
    }
    if (
      refreshStatus === true &&
      !originalRequest._retry &&
      error?.response?.status === 401
    ) {
      originalRequest._retry = true

      stuckedRequestsCount--

      if (stuckedRequestsCount < 1) {
        stuckedRequestsCount = 0
        refreshStatus = false
      }

      return axios(originalRequest)
    }

    if (
      error?.response?.status === 401 &&
      !originalRequest._retry &&
      !responseURL.includes('/refresh') &&
      !refreshStatus &&
      error?.response?.data?.message !== 'SSO_ENFORCED'
    ) {
      stuckedRequestsCount--
      refreshStatus = new Promise((resolve) => {
        refreshStatusPromiseResolve = resolve
      })

      try {
        const { status } = await axios.post('/auth/v1/refresh')

        if (status !== 200) {
          throw new Error('Refresh token failed')
        }
      } catch (error) {
        await store.logout()

        refreshStatusPromiseResolve()
        refreshStatus = false

        removeAppLoading()

        return Promise.reject(error)
      }

      originalRequest._retry = true

      if (store.isAuthenticated) {
        refreshStatusPromiseResolve()
        refreshStatus = true

        return axios(originalRequest)
      }
    }

    if (refreshStatusPromiseResolve) {
      refreshStatusPromiseResolve()
    }

    stuckedRequestsCount--

    if (error?.response?.status === 403) {
      const { negative } = useTrailToast()
      negative({
        content: `Sorry, it seems you don't have the necessary permissions to access this feature. Please contact your administrator or our support team for assistance.`,
        timeout: 8000,
      })
    }

    return Promise.reject(error)
  }
)

export { axios }

export default (app) => {
  app.axios = axios
  app.$http = axios

  app.config.globalProperties.axios = axios
  app.config.globalProperties.$http = axios
}
