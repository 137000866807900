<template>
  <div class="flex flex-col gap-8">
    <VouchersPageHeader
      v-model:search-query="searchQuery"
      v-model:date-range="dateRange"
      v-model:filters="filters"
      v-model:branches-filter="branchesFilter"
      :loading="vouchersListLoading"
      :selected-rows="selectedRows"
      :export-limit="EXPORT_VOUCHERS_LIMIT"
      :total-items="totalItems"
      :vouchers-filters="vouchersFilters"
    />

    <VouchersTable
      v-model:selected-rows="selectedRows"
      v-model:page="page"
      :rows="vouchersList"
      :loading="vouchersListLoading"
      :total-items="totalItems"
      :export-limit="EXPORT_VOUCHERS_LIMIT"
      @patch-voucher="handlePatchVoucher"
    />
  </div>
</template>

<script setup lang="ts">
import type { DateRange } from '@/components/Trail/TrailDateRangePicker.vue'
import type {
  VoucherTableResponse,
  VoucherTableItem,
} from '@/models/voucherModels'

definePage({
  name: 'vouchers',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['pp.voucher.list-vouchers'],
  },
})

const EXPORT_VOUCHERS_LIMIT = 100000

const searchQuery = ref('')
const dateRange = ref<DateRange>()
const filters = ref()
const branchesFilter = ref()

const page = ref(1)
const totalItems = ref<number>()

const vouchersFilters = computed(() => ({
  'filter[search]': searchQuery.value,
  'filter[created_at]': dateRange.value?.start
    ? `${dateRange.value.start} - ${dateRange.value.end}`
    : undefined,
  'filter[status]': filters.value?.status,
  'filter[company_ids]': branchesFilter.value?.branches,
}))

const {
  fetchData: fetchVouchersList,
  data: vouchersListResponse,
  loading: vouchersListLoading,
} = useApiData<VoucherTableResponse>(`/voucher/v1/vouchers`, {
  params: () => ({
    ...vouchersFilters.value,
    page: page.value,
    limit: 25,
  }),
  done: () => {
    totalItems.value = vouchersListResponse.value?.meta.total
  },
  immediate: true,
})

const vouchersList = computed(() => vouchersListResponse.value?.data ?? [])
const selectedRows = ref<VoucherTableItem[]>([])

const handlePatchVoucher = (rowId: number, data: Partial<VoucherTableItem>) => {
  const voucherTableItem = vouchersList.value.find(({ id }) => id === rowId)!
  voucherTableItem.end_user = data.end_user
}

watch(vouchersFilters, () => {
  page.value = 1
})

watch([vouchersFilters, page], () => {
  fetchVouchersList()
})
</script>
