<template>
  <RadixTooltipProvider>
    <router-view />
  </RadixTooltipProvider>

  <TrailToaster />

  <p
    class="fixed right-4 bottom-4 text-trail-caption text-trail-text-caption-normal"
  >
    {{ $t('navigation.footer.version', { version: buildMeta?.version }) }}

    <template v-if="buildMeta?.date">
      ({{ formatDate(buildMeta.date) }} {{ formatTime(buildMeta.date) }})
    </template>
  </p>
</template>

<script lang="ts" setup>
import { inject } from 'vue'
import { formatDate, formatTime } from '@/utils/formatDateTime'
import { isProduction } from '@/utils/environments'

interface BuildMeta {
  isProduction: boolean
  version: string
  date?: string
}
const buildMeta = inject<BuildMeta>('buildMeta')

if (!isProduction()) {
  const metaTag = document.createElement('meta')
  metaTag.name = 'robots'
  metaTag.content = 'noindex'
  document.head.appendChild(metaTag)
}
</script>
