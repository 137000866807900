import { defineStore } from 'pinia'
import { computed } from 'vue'
import { type CompanyData } from '@/models/companyModels'
import type { BranchesResponse } from '@/api/types/branchTypes'
import { transformBranchesResponse } from '@/api/transforms/branchTransforms'

export const useCompanyStore = defineStore('company', () => {
  const pricingModel = computed(() => companyData.value?.pricing_model ?? null)
  const discountPercentage = computed(() => companyData.value?.discount_percentage ?? 0)
  const isNetPrice = computed(() => pricingModel.value === 'net_pricing')
  const branches = computed(() => branchesData.value ? transformBranchesResponse(branchesData.value, false) : [])
  const flattenBranches = computed(() => branchesData.value ? transformBranchesResponse(branchesData.value, true) : [])
  const hasMultipleBranches = computed(() => (flattenBranches.value.length > 1))
  const hasNoSubBranches = computed(() => (flattenBranches.value.length === 1))

  const { data: companyData, loading: companyDataLoading, fetchData: initCompany } =
    useApiData<CompanyData>(`/company/v1/company`, {
      immediate: true,
      done: () => { 
        getBranches()
      }
    })

  const { loading: isBranchesLoading, data: branchesData, fetchData: getBranches } = useApiData(`/company/v1/branches`, {
    transformer: (response: BranchesResponse) => response.data,
    immediate: false,
  })

  return {
    companyData,
    companyDataLoading,
    isNetPrice,
    pricingModel,
    discountPercentage,
    initCompany,
    getBranches,
    hasMultipleBranches,
    hasNoSubBranches,
    branches,
    flattenBranches,
    isBranchesLoading,
  }
})
