<template>
  <div
    class="max-w-125 my-12 md:my-18 lg:my-24 px-2 lg:w-1/3"
    data-testid="reseller-stepper-page"
  >
    <ResellerStepper
      :lead-application="leadApplication"
      :start-step="2"
      stream-type="reseller"
      data-testid="reseller-stepper"
    />
  </div>
</template>

<script lang="ts" setup>
import { useOnboarding } from '@/composables/onboarding/use-onboarding'

definePage({
  name: 'onboarding-reseller-pp',
  meta: { requiresAuth: false },
  path: '/sign-up/onboarding/reseller-pp',
})

const { findLeadApplicationByRouteSlug } = useOnboarding()

const leadApplication = await findLeadApplicationByRouteSlug()
</script>
