<template>
  <div class="grid gap-y-8">
    <h3
      class="text-trail-title1 text-trail-text-title-normal"
      data-testid="creditsPageTitle"
    >
      {{ $t('creditsPageTitle') }}
    </h3>

    <CreditBalanceBanner
      v-if="permissionsStore.canViewBalanceSummary"
      ref="creditBalanceBannerRef"
    />

    <div class="grid items-start lg:grid-cols-[1fr_auto] grid-cols-1">
      <TrailDataTable
        v-model:page="currentPage"
        :columns="columns"
        :rows="transformedBillingHistoryData"
        :loading="loading"
        :no-data-title="$t('creditsNoCreditsTitle')"
        :no-data-description="$t('creditsNoCreditsDescription')"
        data-testid="creditsBillingHistoryTable"
        :total-items="totalItems"
        paginated
      >
        <template #referenceIdShort-loader>
          <RectangleSkeleton class="w-36 h-5 rounded-full" />
        </template>

        <template #category="{ row }">
          <BalanceCategoryBadge
            class="relative"
            :category="row.category"
            :status="row.status"
            :reference="row.referenceTitle"
            :description="row.description"
          />
        </template>

        <template #category-loader>
          <RectangleSkeleton class="w-20 h-8 rounded-full" />
        </template>

        <template #createdAt="{ row }">
          <p class="text-base text-grey-800">
            {{ row.createdAt }}
          </p>
        </template>

        <template #createdAt-loader>
          <RectangleSkeleton class="w-56 h-5 rounded-full" />
        </template>

        <template #convertedAmount="{ row }">
          <div
            :class="[
              'whitespace-nowrap',
              {
                'text-success-500':
                  row.type === BillingType.Credit &&
                  row.status !== BillingHistoryStatus.Failed,
              },
            ]"
          >
            {{ row.convertedAmount }}
          </div>
        </template>

        <template #convertedAmount-loader>
          <RectangleSkeleton class="w-20 h-5 rounded-full" />
        </template>

        <template #user-loader>
          <RectangleSkeleton class="w-44 h-5 rounded-full" />
        </template>
      </TrailDataTable>
    </div>

    <AddCreditsPaymentSuccessModal />

    <MoneyTransferPaymentSuccessModal />
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useAuthStore } from '@/stores/auth'
import { useApiData } from '@/composables/useApiData'
import { transformBillingHistoryResponse } from '@/api/transforms/financeTransforms'
import { usePermissionsStore } from '@/stores/permissions'
import { definePage, useRoute, useRouter } from 'vue-router/auto'
import { BillingHistoryStatus, BillingType } from '@/models/financeModels'
import type { AblyChannel, AblyMessageType } from '@/api/types/ablyTypes'

definePage({
  name: 'credits',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['list-Modules\\Finance\\Models\\AccountTransaction'],
  },
})

const route = useRoute()
const router = useRouter()
const authStore = useAuthStore()
const permissionsStore = usePermissionsStore()
const currentPage = ref<number>(parseInt(<string>route?.query?.page) || 1)
const creditBalanceBannerRef = ref<{
  refreshBalance: () => Promise<void>
}>()
const { t } = useI18n({ useScope: 'global' })
const columns = computed(() => [
  {
    name: t('creditsPageColumnReferenceTitle'),
    key: 'referenceIdShort',
  },
  {
    name: t('creditsPageColumnDateTitle'),
    key: 'createdAt',
  },
  {
    name: t('creditsPageColumnUserNameTitle'),
    key: 'user',
  },
  {
    name: t('creditsPageColumnStatusTitle'),
    key: 'category',
  },
  {
    name: t('creditsPageColumnAmountTitle'),
    key: 'convertedAmount',
  },
])
const totalItems = ref<number>()

const {
  data: billingHistoryData,
  fetchData,
  loading,
} = useApiData(`finance/v1/billing`, {
  transformer: transformBillingHistoryResponse,
  params: () => ({
    page: currentPage.value,
  }),
  done: () => {
    totalItems.value = billingHistoryData.value?.meta?.total
  },
  refetch: { watch: () => authStore.userCurrency },
})

const transformedBillingHistoryData = computed(() => {
  return (
    billingHistoryData?.value?.data.map((item) => {
      if (item.status === BillingHistoryStatus.Pending) {
        item = {
          ...item,
          customRowClass: 'opacity-50 pointer-events-none',
          description: '',
        }
      }
      return item
    }) ?? []
  )
})

const subscribeAbly = async () => {
  const ablyChannel = (await authStore.ablyChannel) as AblyChannel

  ablyChannel.subscribe(
    'finance.transaction.status.changed',
    async function (message: AblyMessageType) {
      if (message.data.status === 'completed') {
        await fetchData()
        await creditBalanceBannerRef?.value?.refreshBalance()
      }
    }
  )
}
onMounted(async () => {
  await subscribeAbly()
})

watch(
  [currentPage],
  () => {
    fetchData()
    router.push({
      path: '/credits',
      query: { ...route.query, page: currentPage.value },
    })
  },
  { deep: true }
)
</script>
