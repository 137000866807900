<template>
  <div class="flex flex-col gap-6">
    <h3 class="text-trail-title3 text-trail-text-title-normal">
      {{ $t('platformOnboardingPageTitle') }}
    </h3>

    <PlatformOnboardingBanner
      :username="userFullName"
      :total-completed-steps="completedSteps.length"
      :total-steps="steps.length"
    />

    <PlatformOnboardingChecklist
      :steps="steps"
      :first-incomplete-step-id="firstIncompleteStepId"
      @mark-as-completed="handleMarkAsCompleted"
      @mark-all-as-completed="showSkipAllDialogue"
    />

    <PlatformOnboardingCompletedDialogue
      v-model="platformOnboardingCompletedDialogue"
    />

    <PlatformOnboardingSkipAllDialogue
      v-model="platformOnboardingSkipAllDialogue"
      @mark-all-as-completed="handleMarkAllAsCompleted"
    />
  </div>
</template>

<script setup lang="ts">
import { storeToRefs } from 'pinia'
import confetti from 'canvas-confetti'
import { breakpointsTailwind } from '@vueuse/core'
import { useAuthStore } from '@/stores/auth'
import { usePlatformOnboardingStore } from '@/stores/platform-onboarding'
import type { PlatformOnboardingStep } from '@/models/platformOnboardingModels'
import {
  sendCompleteOnboardingStepEvent,
  sendSkipOnboardingEvent,
} from '@/utils/dataLayer'

definePage({
  name: 'platform-onboarding',
  meta: {
    requiresAuth: true,
  },
})

const STEP_COMPLETED_CONFETTI_DELAY = 300
const ONBOARDING_COMPLETED_CONFETTI_DELAY = 400
const breakpoints = useBreakpoints(breakpointsTailwind)
const router = useRouter()

const { userFullName, user } = storeToRefs(useAuthStore())
const { steps, onboardingFlowId, completedSteps, isOnboardingCompleted } =
  storeToRefs(usePlatformOnboardingStore())
const firstIncompleteStepId = computed(
  () => steps.value.find((step) => !step.completed)?.id
)

const completedStepId = useLocalStorage<number | undefined>(
  'completedStepId',
  undefined
)
const hasJoinedPartnerPlatformStep = useLocalStorage<string[]>(
  'hasJoinedPartnerPlatformStep',
  []
)

const { createData: markAsCompleted } = useApiData(
  () => `/onboarding/v1/steps/${completedStepId.value}/complete`,
  { immediate: false }
)

const handleClickStep = (step: PlatformOnboardingStep) => {
  if (step.button_link.startsWith('/')) {
    router.push(step.button_link)
  } else {
    window.open(step.button_link, '_blank', 'noopener noreferrer')
  }
}

const handleMarkAsCompleted = (step: PlatformOnboardingStep) => {
  if (step.id < 0) {
    return
  }

  completedStepId.value = step.id
  markAsCompleted()
  sendCompleteOnboardingStepEvent({
    onboardingFlowId: onboardingFlowId.value,
    onboardingStepId: step.id,
  })

  handleClickStep(step)
}

const platformOnboardingSkipAllDialogue = ref(false)
const showSkipAllDialogue = () => {
  platformOnboardingSkipAllDialogue.value = true
}

const platformOnboardingCompletedDialogue = ref(false)
const showOnboardingCompletedDialogue = () => {
  platformOnboardingCompletedDialogue.value = true

  setTimeout(() => {
    confetti({
      particleCount: 300,
      spread: 80,
      origin: { y: 0.5 },
      startVelocity: 70,
      ticks: 300,
      zIndex: 10000,
    })
  }, ONBOARDING_COMPLETED_CONFETTI_DELAY)
}

const { createData: markAllAsCompleted } = useApiData(
  () => '/onboarding/v1/steps/complete-all',
  { immediate: false }
)
const handleMarkAllAsCompleted = () => {
  markAllAsCompleted()
  sendSkipOnboardingEvent({
    onboardingFlowId: onboardingFlowId.value,
  })

  steps.value.forEach((step) => {
    step.completed = true
  })

  showOnboardingCompletedDialogue()
}

const animateLastCompletedStep = () => {
  const stepId = completedStepId.value
  if (!stepId) {
    return
  }
  const completedStep = steps.value.find((step) => step.id === +stepId)

  if (!completedStep) {
    return
  }
  completedStep.completed = true

  const stepElementRect = document
    .getElementById(`step-${completedStep.id}`)
    ?.getBoundingClientRect()
  if (!stepElementRect) {
    return
  }

  setTimeout(() => {
    confetti({
      particleCount: 40,
      spread: 80,
      origin: {
        x: breakpoints.greaterOrEqual('md').value
          ? (stepElementRect.left + stepElementRect.width - 40) /
            window.innerWidth
          : 0.5,
        y:
          (stepElementRect.top + stepElementRect.height / 2) /
          window.innerHeight,
      },
      startVelocity: 20,
      ticks: 80,
      scalar: 0.8,
      zIndex: 9999,
    })
  }, STEP_COMPLETED_CONFETTI_DELAY)
}

const animateCompletedSteps = () => {
  animateLastCompletedStep()

  if (isOnboardingCompleted.value && completedStepId.value) {
    setTimeout(showOnboardingCompletedDialogue, 600)
  }

  completedStepId.value = undefined
}

useEventListener(document, 'visibilitychange', () => {
  if (document.hidden) {
    return
  }

  animateCompletedSteps()
})

onMounted(() => {
  if (isOnboardingCompleted.value && !completedStepId.value) {
    router.push('/home')
  }

  if (!hasJoinedPartnerPlatformStep.value.includes(user.value.id)) {
    hasJoinedPartnerPlatformStep.value.push(user.value.id)
    completedStepId.value = -1
    setTimeout(animateCompletedSteps, 500)
    return
  }

  animateCompletedSteps()
})
</script>
