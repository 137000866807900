import { ref, computed } from 'vue'
import { defineStore } from 'pinia'
import { axios } from '@/http'

export const useFeatureFlagsStore = defineStore('feature-flags', () => {
  const featureFlags = ref<string[]>([])

  async function initFeatureFlags() {
    const { data } = await axios.get('/feature/v1/features')
    featureFlags.value = data?.data
  }

  function isFeatureFlagEnabled(flag: string) {
    return featureFlags.value.includes(flag)
  }

  const corporateMode = computed(() =>
    isFeatureFlagEnabled('partner_hub.corporate_invitations')
  )

  const enableKycPackage = computed(() =>
    isFeatureFlagEnabled('partner_hub.enable_kyc_package')
  )

  const enabledSpendingLimit = computed(() =>
    isFeatureFlagEnabled('partner_hub.afb.spending_limit')
  )

  const multiCurrencyFeature = computed(() =>
    isFeatureFlagEnabled('partner_hub.currency')
  )

  const multiLayerCompanyFeature = computed(() =>
    isFeatureFlagEnabled('multi-layer-company')
  )

  const vouchersFakeDoorTestFeature = computed(() =>
    isFeatureFlagEnabled('partner_hub.esim_vouchers_fake_door_test')
  )

  const vouchersFeature = computed(() =>
    isFeatureFlagEnabled('partner_hub.esim_vouchers')
  )

  return {
    featureFlags,
    initFeatureFlags,
    corporateMode,
    multiCurrencyFeature,
    enableKycPackage,
    enabledSpendingLimit,
    multiLayerCompanyFeature,
    vouchersFakeDoorTestFeature,
    vouchersFeature,
  }
})
