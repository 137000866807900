import { Settings, DateTime } from 'luxon'

// Formatting Date keeping in mind localization and timezones
export const formatDate = (dateTime: string, lang = Settings.defaultLocale) => {
  return DateTime.fromISO(dateTime)
    .setLocale(lang)
    .toLocaleString(DateTime.DATE_SHORT)
}

// Formatting Time keeping in mind localization and timezones
export const formatTime = (dateTime: string, lang = Settings.defaultLocale) => {
  return DateTime.fromISO(dateTime)
    .setLocale(lang)
    .toLocaleString(DateTime.TIME_SIMPLE)
}

// Check validity of the provided dateTime
export const isValidDateTime = (dateTime: string) => {
  return DateTime.fromISO(dateTime).isValid
}
