import { defineStore } from 'pinia'
import type { PlatformOnboardingStepsResponse } from '@/models/platformOnboardingModels'

export const usePlatformOnboardingStore = defineStore(
  'platform-onboarding',
  () => {
    const initSteps = (userId?: number) => {
      const hasJoinedPartnerPlatformStep = useLocalStorage<number[]>(
        'hasJoinedPartnerPlatformStep',
        []
      )
      const completed =
        !!userId && hasJoinedPartnerPlatformStep.value.includes(userId)

      stepsResponse.value = {
        data: stepsResponse.value?.data?.length
          ? [
              {
                id: -1,
                title: 'Join Airalo Partners',
                completed,
                button_text: 'Join',
                button_link: '/start',
              },
              ...stepsResponse.value.data,
            ]
          : [],
        meta: {
          onboarding_flow_id: stepsResponse.value?.meta.onboarding_flow_id,
        },
      }
    }

    const { data: stepsResponse, fetchData: fetchOnboardingSteps } =
      useApiData<PlatformOnboardingStepsResponse>('/onboarding/v1/steps', {
        immediate: false,
      })
    const steps = computed(() => stepsResponse.value?.data ?? [])
    const onboardingFlowId = computed(
      () => stepsResponse.value?.meta.onboarding_flow_id
    )
    const completedSteps = computed(() =>
      steps.value.filter((step) => step.completed)
    )
    const isOnboardingCompleted = computed(
      () => completedSteps.value.length === steps.value.length
    )
    const defaultHomepage = computed(() =>
      isOnboardingCompleted.value ? '/home' : '/start'
    )

    const getOnboardingSteps = async (userId?: number) => {
      await fetchOnboardingSteps()
      initSteps(userId)
    }

    return {
      getOnboardingSteps,
      steps,
      onboardingFlowId,
      completedSteps,
      isOnboardingCompleted,
      defaultHomepage,
    }
  }
)
