<template>
  <div class="flex flex-col gap-6 pt-4 xl:pr-8">
    <div class="flex items-baseline">
      <h3>{{ $t('companySettingsTitle') }}</h3>
    </div>

    <TrailTabGroup
      v-model="selectedTab"
      :tabs="companySettingsTabs"
      tab-list-class="mb-4"
    >
      <template
        v-for="{ value, component } in companySettingsTabs"
        :key="value"
        #[value]
      >
        <component :is="component" />
      </template>
    </TrailTabGroup>
  </div>
</template>

<script setup lang="ts">
import { onMounted, computed } from 'vue'
import { usePlatformOnboardingStore } from '@/stores/platform-onboarding'

definePage({
  name: 'company-settings',
  meta: {
    requiresAuth: true,
  },
})

const route = useRoute<'company-settings'>()
const router = useRouter()

const { canViewCompanySettings, companySettingsTabs } = useCompanySettingsTabs()
const platformOnboardingStore = usePlatformOnboardingStore()

const selectedTab = computed({
  get: () => route.params.tab,
  set: (value) => router.push({ params: { tab: value } }),
})

onMounted(() => {
  const hasPermissionToAccessTab =
    !selectedTab.value ||
    companySettingsTabs.value.some((item) => item.value === selectedTab.value)
  if (!canViewCompanySettings.value || !hasPermissionToAccessTab) {
    router.push(platformOnboardingStore.defaultHomepage)
  }
})
</script>
