<template>
  <div
    class="max-w-125 my-12 md:my-18 lg:my-24 px-2 lg:w-1/3"
    data-testid="corporate-stepper-page"
  >
    <CorporateStepper
      :lead-application="leadApplication"
      data-testid="corporate-stepper"
    />
  </div>
</template>

<script lang="ts" setup>
import { useOnboarding } from '@/composables/onboarding/use-onboarding'

definePage({
  name: 'onboarding-corporate',
  meta: { requiresAuth: false },
  path: '/sign-up/onboarding/corporate',
})

const { findLeadApplicationByRouteSlug } = useOnboarding()

const leadApplication = await findLeadApplicationByRouteSlug()
</script>
