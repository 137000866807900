<template>
  <div class="grid gap-8 px-6" data-testid="addCreditsContainer">
    <div class="grid gap-y-6 py-4 pr-8">
      <router-link
        class="grid grid-flow-col justify-start items-center ml-2 active:opacity-70"
        :to="{ name: 'credits' }"
      >
        <TrailIcon icon="local:arrow-left" size="small" />

        <p class="mx-3 text-trail-title4 text-trail-text-body-normal">
          {{ $t('orderDetailBacktoOrders') }}
        </p>
      </router-link>

      <h3 class="text-trail-title1 text-grey-800">
        {{ $t('addCreditsPageTitle') }}
      </h3>
    </div>

    <div class="grid grid-cols-[3fr_2fr] gap-6">
      <AddCreditsChooseAmount
        :rounded-amount="roundedAmount"
        :default-amounts="defaultAmounts"
        :min-amount="minAmount"
        :is-amount-too-low="isAmountTooLow"
        :loading="loadingAddCreditsAmountsResponse"
        @set-amount="handleSetAmount"
      />

      <AddCreditsPaymentSummary
        :amount="amount"
        :default-amounts="defaultAmounts"
        :rounded-amount="roundedAmount"
        :is-amount-too-low="isAmountTooLow"
        @set-rounded-amount="handleSetRoundedAmount"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useTrailToast } from '@/composables/Trail/useTrailToast'
import { useApiData } from '@/composables/useApiData'
import type { AddCreditsAmountsResponse } from '@/models/financeModels'
import type { AmountType } from '@/models/currencyModels'
import { useAuthStore } from '@/stores/auth'

definePage({
  name: 'add-credits',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['pp.finance.airalo-credits.add'],
  },
})

const authStore = useAuthStore()
const { t } = useI18n()
const { negative } = useTrailToast()

const {
  data: addCreditsAmountsResponse,
  loading: loadingAddCreditsAmountsResponse,
  error: addCreditsAmountsResponseError,
} = useApiData<AddCreditsAmountsResponse>('finance/v1/credits', {
  refetch: {
    watch: () => authStore.userCurrency,
  },
})
const defaultAmounts = computed(
  () => addCreditsAmountsResponse.value?.amounts || []
)
const minAmount = computed(() => addCreditsAmountsResponse.value?.min_amount)

const amount = ref<AmountType>({
  amount: 0,
  currency: authStore.userCurrency,
})
const isAmountTooLow = computed(
  () => !minAmount.value || amount.value.amount < minAmount.value.amount
)

const handleSetAmount = (value: number) => {
  amount.value = {
    amount: value,
    currency: authStore.userCurrency,
  }
}

const roundedAmount = ref<AmountType>({
  amount: 0,
  currency: authStore.userCurrency,
})

const handleSetRoundedAmount = (value: number) => {
  roundedAmount.value = {
    amount: value,
    currency: authStore.userCurrency,
  }
}

watch(addCreditsAmountsResponseError, (error) => {
  if (!error) {
    return
  }

  negative({
    content: t('errorPaymentFailedTryAgain'),
  })
})
</script>
