<template>
  <AuthPagesLayout>
    <div
      v-if="verifyTokenLoading"
      class="z-9999 absolute top-64 left-1/2 -translate-x-1/2"
    >
      <TrailIcon
        :size="32"
        icon="local:spinner"
        color="highlight"
        class="animate-spin"
        data-testid="trailButtonLoader"
      />
    </div>

    <ResetTokenExpired v-else-if="isTokenExpired" />

    <Reset v-else />
  </AuthPagesLayout>
</template>

<script setup lang="ts">
import { HttpStatusCode } from 'axios'

definePage({
  name: 'reset-password',
})

const router = useRouter()
const route = useRoute()
const { t } = useI18n()
const { negative } = useTrailToast()

const {
  createData: verifyToken,
  loading: verifyTokenLoading,
  error: verifyTokenError,
} = useApiData('/auth/v1/password/reset/verify-token', {
  immediate: false,
})
const isTokenExpired = computed(
  () => verifyTokenError.value?.response?.status === HttpStatusCode.BadRequest
)

onMounted(() => {
  const { email, token } = route.query
  if (!email || !token) {
    negative({ content: t('resetPasswordInvalidLink') })
    router.push('/')
  }

  verifyToken({ email, token })
})
</script>
