<template>
  <AuthPagesLayout>
    <img
      v-if="sentToEmail"
      src="@/assets/images/svg/auth-pages-forgot-password.svg?data=raw"
      alt=""
      class="mb-2"
    />

    <div>
      <h3 class="text-trail-display2 text-trail-text-display-normal">
        {{
          sentToEmail
            ? $t('scenes.forgot.password.sent-email-title')
            : $t('scenes.forgot.password.title')
        }}
      </h3>

      <div class="text-trail-body2 text-trail-text-body-normal mt-2">
        <p v-if="sentToEmail" data-testid="forgot-password-instructions">
          {{
            $t('scenes.forgot.password.sent-email-instruction', {
              email: sentToEmail,
            })
          }}
        </p>

        <p v-else>
          {{ $t('scenes.forgot.password.information') }}
        </p>
      </div>
    </div>

    <Forgot v-if="!sentToEmail" @show-instructions="handleShowInstructions" />

    <ForgotPasswordCheckEmailButton
      :email="sentToEmail"
      data-testid="forgot-password-back-2-login-link"
    />
  </AuthPagesLayout>
</template>

<script setup lang="ts">
import { ref } from 'vue'

definePage({
  name: 'forgot-password',
})

const sentToEmail = ref('')

const handleShowInstructions = (email: string) => {
  sentToEmail.value = email
}
</script>
