<template>
  <div class="mt-6 flex flex-col gap-8">
    <div
      class="grid grid-flow-col justify-start items-center cursor-pointer ml-2 w-fit"
      @click="goBackToCostCentre"
    >
      <TrailIcon icon="local:arrow-left" />

      <p class="mx-2 text-trail-title4 text-trail-text-body-normal">
        {{ $t('costCentreBackToList') }}
      </p>
    </div>

    <div class="flex flex-col gap-8">
      <div class="flex justify-between items-center">
        <div class="flex gap-2">
          <h1 class="text-trail-title1 text-trail-text-title-normal">
            {{ $t('costCentreDetailsTitle') }}
          </h1>

          <TrailDivider
            v-if="!!costCentreName?.length"
            size="normal"
            orientation="vertical"
          />

          <h1 class="text-trail-title1 text-trail-text-title-normal">
            {{ costCentreName }}
          </h1>
        </div>

        <TrailDropdown
          :label="$t('costCentreDetailActions')"
          :options="actionsOptions"
          @update:modelValue="handleActionClick($event)"
        />
      </div>

      <CostCentreDetailTable
        :rows="costCentreDetailListData"
        :pagination="costCentreDetailListPaginationData"
        :loading="costCentreDetailListLoading"
        @pagination-changed="costCentreDetailPageChanged"
        @unassign-cost-centre-row="handleUnassignCostCentreRow"
      />
    </div>

    <AssignUserCostCentreModal
      v-model:open="assignUserCostCentreModalOpen"
      :cost-centre="{ id: costCentreId, name: costCentreName }"
      @reload-table="costCentreDetailListFetchData"
    />

    <BulkAssignUserCostCentreModal
      v-model:open="bulkAssignUserCostCentreModelOpen"
      :cost-centre="{ id: costCentreId, name: costCentreName }"
      @reload-table="costCentreDetailListFetchData"
      @show-non-existing-users="nonExistingUsers = $event"
    />

    <CostCentreNonExistingUsersModal
      v-model:open="isNonExistingUsersModalOpen"
      :users="nonExistingUsers"
    />

    <RenameCostCentreModal
      v-model:open="renameCostCentreModalOpen"
      :cost-centre-id="costCentreId"
      @update:name="costCentreName = $event"
    />

    <TrailDialogue
      v-model="deleteCostCentreDialogue"
      :primary-action="{
        label: $t('deleteCostCentreDialoguePrimary'),
        destructive: true,
      }"
      :secondary-action="{ label: $t('deleteCostCentreDialogueSecondary') }"
      @click:primary="handleDeleteCostCentre"
    >
      <template #title>
        {{ $t('deleteCostCentreDialogueTitle') }}
      </template>

      <template #description>
        <div class="flex flex-col items-center">
          <p>
            {{ $t('deleteCostCentreDialogueDescription') }}
          </p>
        </div>
      </template>
    </TrailDialogue>

    <TrailDialogue
      v-model="unassignCostCentreDetailDialogue"
      :primary-action="{ label: $t('unassignCostCentreDialoguePrimary') }"
      :secondary-action="{ label: $t('unassignCostCentreDialogueSecondary') }"
      @click:primary="handleUnassignCostCentreDetail"
    >
      <template #title>
        {{ $t('unassignCostCentreDetailDialogueTitle') }}
      </template>

      <template #description>
        <div class="flex flex-col items-center">
          <p>
            {{ $t('unassignCostCentreDetailDialogueDescription') }}
          </p>
        </div>
      </template>
    </TrailDialogue>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { useTrailToast } from '@/composables/Trail/useTrailToast.ts'
import { useApiData } from '@/composables/useApiData.ts'
import {
  transformCostCentreDetailResponse,
  transformCostCentreDetailListResponse,
} from '@/api/transforms/costCentreTransforms.ts'
import type { CostCentreUser } from '@/api/types/costCentreTypes.ts'

definePage({
  name: 'cost-centre-details',
  meta: {
    requiresAuth: true,
    requiredPermissions: ['pp.company.update-billing-details'],
  },
})

const { t } = useI18n()
const route = useRoute<'cost-centre-details'>()
const router = useRouter()
const { positive, negative } = useTrailToast()
const deleteCostCentreDialogue = ref(false)
const costCentreDetailListCurrentPage = ref(1)
const costCentreName = ref<string>('')
const costCentreId = computed(() => parseInt(route.params.id))
const unassignCostCentreDetailDialogue = ref(false)
const unassignCostCentreDetailRow = ref<CostCentreUser>()
const assignUserCostCentreModalOpen = ref(false)
const bulkAssignUserCostCentreModelOpen = ref(false)
const renameCostCentreModalOpen = ref(false)
const nonExistingUsers = ref<string[]>([])
const isNonExistingUsersModalOpen = computed(
  () => nonExistingUsers.value.length > 0
)

const actionsOptions = computed(() => [
  {
    label: t('costCentreDetailBulkAssignUserLabel'),
    value: 'bulk-assign-user',
  },
  { label: t('costCentreDetailAssignUserLabel'), value: 'assign-user' },
  { label: t('costCentreDetailRenameLabel'), value: 'rename' },
  { label: t('costCentreDetailDeleteLabel'), value: 'delete' },
])

const goBackToCostCentre = () =>
  router.push('/platform/company-settings/cost-centre')

const handleActionClick = (value: string) => {
  switch (value) {
    case 'bulk-assign-user':
      bulkAssignUserCostCentreModelOpen.value = true
      break
    case 'assign-user':
      assignUserCostCentreModalOpen.value = true
      break
    case 'rename':
      renameCostCentreModalOpen.value = true
      break
    case 'delete':
      deleteCostCentreDialogue.value = true
      break
  }
}

const { deleteData: deleteCostCentre, error: deleteCostCentreError } =
  useApiData(() => `/cost-centre/v1/centres/${costCentreId.value}`, {
    immediate: false,
    done: () => {
      deleteCostCentreDialogue.value = false
      positive({
        content: t('deleteCostCentreSuccessMessage'),
        timeout: 3000,
      })
      goBackToCostCentre()
    },
  })

const handleDeleteCostCentre = async () => {
  await deleteCostCentre()

  if (deleteCostCentreError.value) {
    negative({
      content:
        deleteCostCentreError.value.response?.data.message ?? t('errorTitle'),
      timeout: 3000,
    })
  }
}

const { data: costCentreDetailData } = useApiData(
  `/cost-centre/v1/centres/${costCentreId.value}`,
  {
    transformer: transformCostCentreDetailResponse,
    done() {
      costCentreName.value = costCentreDetailData.value?.name ?? ''
    },
    immediate: true,
  }
)

const handleUnassignCostCentreRow = (row: CostCentreUser) => {
  unassignCostCentreDetailDialogue.value = true
  unassignCostCentreDetailRow.value = row
}

const { createData: unassignUser, error: unassignUserError } = useApiData(
  `/cost-centre/v1/centres/${costCentreId.value}/users/unassign`,
  {
    immediate: false,
    done: () => {
      unassignCostCentreDetailDialogue.value = false
      positive({
        content: t('unassignCostCentreSuccessMessage', {
          userName: `${unassignCostCentreDetailRow.value?.full_name ?? ''}`,
          costCentreName: costCentreName.value,
        }),
        timeout: 3000,
      })
      costCentreDetailListFetchData()
    },
  }
)

const handleUnassignCostCentreDetail = async () => {
  await unassignUser({
    user_ids: [unassignCostCentreDetailRow.value?.id],
  })

  if (unassignUserError.value) {
    negative({
      content:
        unassignUserError.value.response?.data.message ?? t('errorTitle'),
    })
  }
}

const {
  data: costCentreDetailList,
  fetchData: costCentreDetailListFetchData,
  loading: costCentreDetailListLoading,
} = useApiData(`/cost-centre/v1/centres/${costCentreId.value}/users`, {
  transformer: transformCostCentreDetailListResponse,
  params: () => ({
    page: costCentreDetailListCurrentPage.value,
    limit: 25,
  }),
  immediate: true,
})

const costCentreDetailListData = computed(
  () => costCentreDetailList.value?.data ?? []
)
const costCentreDetailListPaginationData = computed(
  () => costCentreDetailList.value?.meta
)
const costCentreDetailPageChanged = (page: number) => {
  costCentreDetailListCurrentPage.value = page
  costCentreDetailListFetchData()
}
</script>
