import { removeAppLoading } from '@/utils/auth'
import { createRouter, createWebHistory } from 'vue-router'
import { routes } from 'vue-router/auto-routes'
import { useAuthStore } from '@/stores/auth'
import { useConfirmNavigationStore } from '@/stores/confirm-navigation'
import { usePlatformOnboardingStore } from '@/stores/platform-onboarding'
import { hasPermissions } from '@/utils/permissions'
import i18n from '@/i18n'
import { axios } from '@/http'

function checkRedirectURL() {
  const { pathname, search } = window.location
  const currentURL = pathname + search
  if (currentURL) localStorage.setItem('ret_url', currentURL)
}

const router = createRouter({
  routes,
  history: createWebHistory(import.meta.env.BASE_URL),
  base: import.meta.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  },
})

router.beforeEach(async (to, from, next) => {
  if (to.meta.requiresAuth) {
    const tokenRedirectUrl = localStorage.getItem('ret_url')
    const authStore = useAuthStore()

    if (!authStore.isAuthenticated) {
      checkRedirectURL()

      try {
        const { status } = await axios.post('/auth/v1/refresh')
        if (status === 200) {
          authStore.setUserAuthenticaitonStatus(true)
          next()
        } else {
          authStore.logout()
          next('/sign-in')
        }
      } catch (error) {
        authStore.logout()
        next('/sign-in')
      }
    } else if (tokenRedirectUrl && tokenRedirectUrl !== '/') {
      next(tokenRedirectUrl)
      localStorage.removeItem('ret_url')
    } else {
      next()
    }
  } else {
    const authStore = await useAuthStore()
    const platformOnboardingStore = usePlatformOnboardingStore()

    await authStore.initialize()
    if (authStore.user?.id) {
      await platformOnboardingStore.getOnboardingSteps(authStore.user.id)
    }

    if (authStore.hasUser && !to.path.includes('/partnerships/')) {
      next(platformOnboardingStore.defaultHomepage)
    } else {
      next()
    }
  }

  removeAppLoading()
})

router.beforeEach(async (to, from, next) => {
  const routeName = to.name
  const translationKey = `components.nav.menu.${routeName}`
  document.title = `Airalo Partner Platform - ${i18n.global.t(translationKey)}`

  const authStore = useAuthStore()
  const platformOnboardingStore = usePlatformOnboardingStore()

  if (authStore.isInitializedComp === false && authStore.hasUser === false) {
    await authStore.initialize()
    if (authStore.user?.id) {
      await platformOnboardingStore.getOnboardingSteps(authStore.user.id)
    }
  }

  if (authStore.hasUser) {
    if (hasPermissions(to.meta.requiredPermissions)) {
      next()
    } else {
      next(platformOnboardingStore.defaultHomepage)
    }
  }

  next()
})

router.beforeEach((to, from, next) => {
  const confirmNavigationStore = useConfirmNavigationStore()
  if (confirmNavigationStore.requiresLeaveConfirmation) {
    confirmNavigationStore.setPendingRoute({ to, from, next })
    confirmNavigationStore.setIsLeaving(true)
  } else {
    next()
  }
})

router.onError((error, to) => {
  if (error.message.includes('Failed to fetch dynamically imported module')) {
    window.location = to.fullPath
  }
})

export default router
