import { useAuthStore } from '@/stores/auth'
import { usePermissionsStore } from '@/stores/permissions'
import { useFeatureFlagsStore } from '@/stores/feature-flags'
import { usePlatformOnboardingStore } from '@/stores/platform-onboarding'
import { useCompanyStore } from '@/stores/company.store'
import { axios } from '@/http'

export const setupPermissions = (store) => {
  const permissionsStore = usePermissionsStore()
  permissionsStore.setPermissions(store.user)
}

export async function login(email, password) {
  const authStore = useAuthStore()
  const featureFlagsStore = useFeatureFlagsStore()
  const platformOnboardingStore = usePlatformOnboardingStore()
  const companyStore = useCompanyStore()

  try {
    const { data } = await axios.post('/auth/v1/login', { email, password })

    await featureFlagsStore.initFeatureFlags()
    await platformOnboardingStore.getOnboardingSteps(data.user.id)
    companyStore.initCompany()
    authStore.setUserAuthenticaitonStatus(true)
    authStore.setUser(data.user)
    setupPermissions(authStore)
  } catch (err) {
    console.error(err)
    authStore.setUserAuthenticaitonStatus(false)
    return Promise.reject(err)
  }
}

export async function logout() {
  try {
    await axios.post('/auth/v1/logout')
  } finally {
    const authStore = useAuthStore()
    authStore.logout()
  }
}

export async function refreshToken() {
  const authStore = useAuthStore()

  try {
    await axios.post('/auth/v1/refresh')
    authStore.setUserAuthenticaitonStatus(true)
  } catch (error) {
    authStore.logout()
  }
}

export function removeAppLoading() {
  const authStore = useAuthStore()

  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
  setupPermissions(authStore)
}
