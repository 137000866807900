import { datadogRum } from '@datadog/browser-rum'
import type { Plugin } from 'vue'

export default {
    install: (app, options) => {
        const { datadogApplicationId, datadogClientToken, datadogSite, datadogService, environment, version } = options

        try {
            if (datadogApplicationId) {
                const datadog = datadogRum.init({
                    applicationId: datadogApplicationId,
                    clientToken: datadogClientToken,
                    site: datadogSite,
                    service: datadogService,
                    env: environment,
                    version: version.split('/').pop(),
                    sessionSampleRate: 1,
                    sessionReplaySampleRate: 20,
                    trackUserInteractions: true,
                    trackResources: true,
                    trackLongTasks: true,
                    defaultPrivacyLevel: 'mask-user-input',
                    allowedTracingUrls: [
                        /^(?:https:\/\/|https:\/\/www.)(?:feat|chore|epic|fix|refactor)-(?:ap|ppo)-\d+.platform.eks.partners.airalo.com$/,
                        'https://dev.partners.airalo.com',
                      ],
                })
                app.provide('datadog', datadog)
            }
        } catch (err) {
            console.error('Not connected to Datadog', err)
        }
    }
} satisfies Plugin
